import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import columnChart from '@/components/charts/columnChart.vue'

import { generateCodeFrame } from 'vue-template-compiler';
import { handler } from 'flowbite/plugin';
import moment from 'moment';

export default {
    name: "AllCreatives",
    components: {
        Loader, dualAxisChart, columnChart
    },
    data() {
        return {
            loader: false,
            tableLoader: false,
            chartLoader: false,
            dateRange: {
                startDate: moment().subtract(1, 'week').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            intervalsList: [
                {
                    key: 'day',
                    label: 'Day'
                },
                {
                    key: 'week',
                    label: 'Week'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
                {
                    key: 'year',
                    label: 'Year'
                },
            ],
            selectedInterval: {
                key: 'day',
                label: 'Day'
            },
            selectedLineGraphMetric: 'ROAS',
            selectedBarGraphMetric: 'Purchase',
            graphDropDownData: [],
            lineGraphDropdownMetric: [],
            barGraphDropdownMetric: [],
            timeLineGraphData: {},
            isMetricDropdown: false,
            metrics: [],
            viewAllCampaignData: '',
            viewMeteCreativeTimelineGraphData: '',
            tableHeader: [],
            tableData: [],
            filterBy: "",
            isFilter: false,
            selectedNumericCampaignValue: "",
            numericFiltersFields: [],
            selectedNumericMatricsValue: "",
            matricsInputValue: "",
            selectedStringCampaignValue: "",
            stringFiltersFields: [],
            selectedStringMatricsValue: "",
            StringMatricsInputValue: "",
            storeNumericFilterValue: [],
            storeStringFilterValue: [],
            // save view variable
            selectedViewName: 'Default View',
            isSavedViewEdid: false,
            viewList: [],
            viewSaveViewList: '',
            isDeleteDashboardModal: false,
            dashboardDeleteLoader: false,
            renameDashboarddNameLoader: false,
            renameDashboardName: '',
            isCommonModal: false,
            saveViewLoader: false,
            isMenuPopupOpen: false,
            isNewDashboard: false,
            // month wise graph variable 
            monthWiseGraphData: '',
            monthDateRange: {
                startDate: moment().subtract(1, 'week').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            viewMonthWiseGraphData: '',
            monthChartLoader: false,
            momTableLoader: false,
            MOMPerformanceTableData: { data: [] },
            selectedMonthPicker: moment().format("YYYY-MM"),
            // export button section 
            isExportCampaignTable: false,
            isExportMOMPerformanceTable: false
        }
    },
    created() {
        window.addEventListener("click", this.close)
        window.addEventListener("click", this.closeTabMenu)

    },
    mounted() {
        // this.getSavedCreatives();
        // this.dataAPIs();
        this.$nextTick(() => {
            $("#mom_performance_table").DataTable();
        });
        this.getSaveViewListData();
        this.initializeModal();
    },

    watch: {
        metrics: {
            deep: true,
            handler() {
                this.selectMetric();
            }
        }
    },

    beforeDestroy() {
        window.removeEventListener("click", this.close)
        window.removeEventListener("click", this.closeTabMenu)
    },

    methods: {
        dataAPIs() {
            this.dateRange.startDate = this.$store.state.store.filter.startDate
            this.dateRange.endDate = this.$store.state.store.filter.endDate,
                this.storeStringFilterValue = this.$store.state.filter.metaCretiveFilter.stringType,
                this.storeNumericFilterValue = this.$store.state.filter.metaCretiveFilter.numericType,
                this.selectedLineGraphMetric = this.$store.state.filter.metaCreativeTimelineDataGraphFilter.metric_1
            this.selectedBarGraphMetric = this.$store.state.filter.metaCreativeTimelineDataGraphFilter.metric_2
            this.selectedInterval = {
                key: this.$store.state.filter.metaCreativeTimelineDataGraphFilter.interval,
                label: this.$store.state.filter.metaCreativeTimelineDataGraphFilter.interval.charAt(0).toUpperCase() + this.$store.state.filter.metaCreativeTimelineDataGraphFilter.interval.slice(1)
            }
            this.getAllCreativeData();
            this.getMeteCreativeTimelineGraphData();
            this.getMeteCreativeMonthWiseGraphData();
            this.getMOMPerformanceCreativeData();
        },
        formateMonth(date) {
            return moment(date).format("MMMM")
        },
        initializeModal() {
            const $deleteModal = document.getElementById("modalDelete");
            const deleteModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: false,
                onClose: () => {
                    //    this.shopifyStoreName=''
                },
            };
            const $commonModal = document.getElementById("commonModal");
            const commonModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: false,
                onClose: () => {
                    //    this.shopifyStoreName=''
                },
            };
            this.isDeleteDashboardModal = new Modal($deleteModal, deleteModalOptions);
            this.isCommonModal = new Modal($commonModal, commonModalOptions);

        },

        exportCampaignLevelData() {
            this.isExportCampaignTable = true
            let url = "/api/data/downloadtoxlsx";
            const payload = {
                report_title: "All Campaign Level Ads",
                headers: this.tableHeader?.map(({ isAvailable, ...rest }) => rest),

                data: this.viewAllCampaignData.data.map((data) => {
                    const filteredData = {};
                    this.tableHeader.forEach((column) => {
                        if (data.hasOwnProperty(column.key)) {
                            filteredData[column.key] = data[column.key];
                        }
                    });
                    return filteredData;
                })
            };
            axios.post(url, payload).then((res) => {
                window.location = res.data.url;
                this.isExportCampaignTable = false
            }).catch((err) => {
                console.error(err);
                this.isExportCampaignTable = false

            })
        },

        exportMonthWisePerformanceTableData() {
            this.isExportMOMPerformanceTable = true
            let url = "/api/data/downloadtoxlsx";
            const payload = {
                report_title: "MOM Funnel Wise Performance",
                headers: this.MOMPerformanceTableData.headers,
                data: this.MOMPerformanceTableData.data
            };
            axios.post(url, payload).then((res) => {
                window.location = res.data.url;
                this.isExportMOMPerformanceTable = false
            }).catch((err) => {
                console.error(err);
                this.isExportMOMPerformanceTable = false

            })
        },

        openDeleteModal() {
            this.isDeleteDashboardModal.show()
        },

        openTabMenu() {
            if (!this.isMenuPopupOpen)
                this.isMenuPopupOpen = true
            else this.isMenuPopupOpen = false
        },

        closeTabMenu(event) {

            if (this.isMenuPopupOpen) {
                const tabMenuElement = this.$refs.tabMenuRef[0]; // Access DOM element using ref
                if (tabMenuElement && tabMenuElement.contains && !tabMenuElement.contains(event.target)) {
                    this.isMenuPopupOpen = false;
                }
            }
        },

        openCommonModal(modalType) {
            if (modalType == 'rename') {
                this.renameDashboardName = this.selectedViewName
            }
            if (modalType == 'New Dashboard') {
                this.isNewDashboard = true
            }
            this.isCommonModal.show()

        },

        closeCommonModal() {
            this.isNewDashboard = false
            this.renameDashboardName = ''
            this.isCommonModal.hide()
        },

        handleIsFilter() {
            const existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].field === this.selectedNumericCampaignValue
            );

            if (existingValue) {
                this.selectedNumericMatricsValue = existingValue[0].operation;
                this.matricsInputValue = existingValue[0].value;
            }
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
            this.isFilter = true;
        },

        clearFilter() {
            this.isFilter = false;
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
        },

        RemoveAllBadgeValue() {
            this.storeNumericFilterValue = [];
            this.storeStringFilterValue = [];
        },



        RemoveBadgeValue(data) {
            const index = this.storeNumericFilterValue.flat().findIndex(
                (item) =>
                    item.field === data.field &&
                    item.operation === data.operation &&
                    item.value === data.value
            );

            if (index !== -1) {
                this.storeNumericFilterValue.splice(index, 1);
            }

            for (let i = 0; i < this.storeStringFilterValue.length; i++) {
                const itemArray = this.storeStringFilterValue[i];
                const matchIndex = itemArray.findIndex(
                    (item) =>
                        item.field === data.field &&
                        item.operation === data.operation &&
                        item.value === data.value
                );

                if (matchIndex !== -1) {
                    this.storeStringFilterValue.splice(i, 1);
                    break;
                }
            }
        },

        handleFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
            if (data.length <= 0) {
                return false;
            }

            let existingValue = storeFilterValue.find(
                (item) => item[0].field === data && item[0].operation === this[selectedMatricsValueKey]
            );

            if (existingValue) {
                this[matricsInputValueKey] = existingValue[0].value;
            } else {
                this[matricsInputValueKey] = "";
            }
        },

        handleNumericFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeNumericFilterValue,
                'selectedNumericMatricsValue',
                'matricsInputValue'
            );
        },

        handleNumericMatricFilterModal(data) {
            let existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].field === this.selectedNumericCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.matricsInputValue = existingValue[0].value;
            } else {
                this.matricsInputValue = ""
            }
        },

        handlePostFilters({
            selectedCampaignValue,
            selectedMatricsValue,
            matricsInputValue,
            storeFilterValue
        }) {
            let existingFilter = storeFilterValue.find(
                (item) => item[0].field === selectedCampaignValue && item[0].operation === selectedMatricsValue
            );

            if (existingFilter) {
                // existingFilter[0].operation = selectedMatricsValue;
                existingFilter[0].value = isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue);
            } else {
                storeFilterValue.push([
                    {
                        operation: selectedMatricsValue,
                        field: selectedCampaignValue,
                        value: isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue)
                    }
                ]);
            }
        },

        postNumericalFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedNumericCampaignValue,
                selectedMatricsValue: this.selectedNumericMatricsValue,
                matricsInputValue: this.matricsInputValue,
                storeFilterValue: this.storeNumericFilterValue,
            });
            this.isFilter = false;
        },

        handleStringFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeStringFilterValue,
                'selectedStringMatricsValue',
                'StringMatricsInputValue'
            );
        },

        handleStringMatricsFilterModal(data) {
            let existingValue = this.storeStringFilterValue.find(
                (item) => item[0].field === this.selectedStringCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.StringMatricsInputValue = existingValue[0].value;
            } else {
                this.StringMatricsInputValue = ""
            }
        },

        handlePostStringFilters() {
            this.storeStringFilterValue.push([
                {
                    operation: this.selectedStringMatricsValue,
                    field: this.selectedStringCampaignValue,
                    value: this.StringMatricsInputValue
                }
            ])
            this.isFilter = false;
        },

        selectMetric(metric) {
            this.tableHeader = this.metrics.filter(data => data.isAvailable);

            this.$store.dispatch('store/setFBCreativeMetric', this.tableHeader)
            if ($.fn.DataTable.isDataTable('#reports_table')) {
                $('#reports_table').DataTable().destroy();
            }
            this.$nextTick(() => {
                this.processTable('#reports_table', 1);
            });
        },

        removeSelectedMetric(idx) {
            this.metrics[idx].isAvailable = false
        },

        applyFilter() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
            this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
            this.$store.dispatch('store/setDate', this.dateRange)
            const filterByState = {
                stringType: this.storeStringFilterValue,
                numericType: this.storeNumericFilterValue
            }
            this.$store.dispatch('filter/setMetaCreatvieFilterBy', filterByState)
            this.getAllCreativeData();
            this.getMeteCreativeTimelineGraphData();
            this.getMeteCreativeMonthWiseGraphData();
            this.getMOMPerformanceCreativeData();
        },

        setInterval(selectedInterval) {
            this.selectedInterval = selectedInterval
            this.getMeteCreativeTimelineGraphData();
        },

        toggleAdMetricDropdown() {
            if (this.isMetricDropdown) {
                this.isMetricDropdown = false
            }
            else {
                this.isMetricDropdown = true
            }
        },

        close(event) {
            if (this.metrics.length) {
                if (!this.$refs.metricDropdownRef.contains(event.target)) {
                    this.isMetricDropdown = false
                }
            }

        },

        getAllCreativeData() {
            this.tableLoader = true;
            this.viewAllCampaignData = '';
            this.tableData = ''
            $('#reports_table').DataTable().destroy();

            const url = `/api/v1/meta/creativedatanew?start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&numarical_filters=${JSON.stringify(this.storeNumericFilterValue.flat())}&string_filters=${JSON.stringify(this.storeStringFilterValue.flat())}`;
            axios.get(url).then(res => {
                this.tableData = res.data
                this.viewAllCampaignData = this.tableData;
                this.numericFiltersFields = res.data.numarical_filters;
                this.stringFiltersFields = res.data.string_filters;
                this.selectedNumericCampaignValue = this.numericFiltersFields.fields[0];
                this.selectedNumericMatricsValue = this.numericFiltersFields.matrics[0];
                this.selectedStringCampaignValue = this.stringFiltersFields.fields[0];
                this.selectedStringMatricsValue = this.stringFiltersFields.matrics[0];
                this.generateMetricsForFilter();
                this.$nextTick(() => {
                    this.processTable('#reports_table')
                })
                this.tableLoader = false;
            }).catch(err => {
                this.tableLoader = false;
                console.log("Error in getting saved reports", err)
            })
        },
        async getMOMPerformanceCreativeData() {
            this.momTableLoader = true;
            this.MOMPerformanceTableData.data = '';
            this.tableData = ''
            $('#mom_performance_table').DataTable().destroy();

            const url = '/api/v1/meta/creativetimleine/graph2';
            const payload = {
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
                // "start_date": "2024-01-01",
                // "end_date": "2025-02-06",
            }
            await axios.post(url, payload).then(res => {
                if (!res.data) return false;
                this.MOMPerformanceTableData = res.data
                // Count occurrences of each campaign type
                let countMap = {};
                let processedData = [];
                res.data.data.forEach(item => {
                    countMap[item["Campaign Type"]] = (countMap[item["Campaign Type"]] || 0) + 1;
                });

                let seen = {};

                // Process data and add rowspan info
                res.data.data.forEach(item => {
                    let newItem = { ...item };

                    if (!seen[item["Campaign Type"]]) {
                        newItem.rowspan = countMap[item["Campaign Type"]];
                        seen[item["Campaign Type"]] = true;
                    } else {
                        newItem.rowspan = 0; // Avoid duplicate rowspan
                    }

                    processedData.push(newItem);
                });
                this.MOMPerformanceTableData.data = processedData
                this.$nextTick(() => {
                    this.processTable('#mom_performance_table')
                })
                this.momTableLoader = false;
            }).catch(err => {
                this.momTableLoader = false;
                console.log("Error in getting saved reports", err)
            })
        },

        generateMetricsForFilter() {
            let selectedMetrics = this.$store.state.store.selectedFBCreativeMetric.map(data => data.key)
            this.metrics = this.viewAllCampaignData.headers.map(data => {
                return {
                    isAvailable: selectedMetrics.includes(data.key) ? true : false,
                    label: data.value,
                    key: data.key
                }
            })
            this.tableHeader = selectedMetrics
            this.$store.dispatch('store/setFBCreativeMetric', this.tableHeader)
        },

        // save view section 
        getSaveViewListData(isUpdateName = true) {
            this.loader = true
            const url = "/api/v1/meta/savecreativeview"
            axios.get(url).then((res) => {
                this.viewList = res.data.data.views
                if (this.viewList.length) {
                    if (isUpdateName) {
                        this.selectedViewName = this.viewList[0].name
                        this.isNewDashboard = false
                        this.getSelectedViewFilterData(this.selectedViewName);
                    }
                    if (this.isNewDashboard) {
                        this.getSelectedViewFilterData(this.selectedViewName);
                        this.isNewDashboard = false

                    }
                }
                else {
                    this.viewList = [{ name: 'Default View', order: 1 }]
                    this.selectedViewName = 'Default View',
                        this.isNewDashboard = true
                    this.dataAPIs();
                }
                this.loader = false
            }).catch((err) => {
                console.log(err)
            })
        },

        async getSelectedViewFilterData(tabName) {
            this.selectedViewName = tabName
            const url = "/api/v1/meta/savecreativeview?view_name=" + this.selectedViewName
            await axios.get(url).then((res) => {
                if (res.data.data.metrics.length) {
                    this.$store.dispatch('store/setFBCreativeMetric', res.data.data.metrics)
                }
                if (res.data.data.graphs.length)
                    this.$store.dispatch('filter/setMetaCreatvieTimelineGraphData', res.data.data.graphs[0])
                const dateRange = {
                    startDate: res.data.data.start_date,
                    endDate: res.data.data.end_date
                }
                const filterByState = {
                    stringType: res.data.data.string_filters ? res.data.data.string_filters.map(data => [data]) : [],
                    numericType: res.data.data.numarical_filters ? res.data.data.numarical_filters.map(data => [data]) : []
                }
                this.$store.dispatch('filter/setMetaCreatvieFilterBy', filterByState)
                this.$store.dispatch('store/setDate', dateRange)
                this.dataAPIs();
                // this.$store.dispatch('filter/setMetaCreatvieFilterBy', filterByState)
            }).catch((err) => {
                console.log(err)
            })
        },


        createNewDashboard() {
            this.isCommonModal.show();
        },

        postSelectedFilter() {
            const url = "/api/v1/meta/savecreativeview"
            const payload = {
                "view_name": this.selectedViewName,
                "start_date": moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                "end_date": moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                "numarical_filters": this.storeNumericFilterValue.flat(),
                "string_filters": this.storeStringFilterValue.flat(),
                "metrics": this.tableHeader,
                "graphs": [
                    {
                        "name": "Timeline Data",
                        "page_depth": 0,
                        "interval": this.selectedInterval.key,
                        "metric_1": this.selectedLineGraphMetric,
                        "metric_2": this.selectedBarGraphMetric
                    },
                    {
                        "name": "Timeline Data",
                        "page_depth": 1,
                        "interval": "day",
                        "metric_1": "Impressions",
                        "metric_2": "Reach"
                    }
                ]
            }

            if (this.isNewDashboard) {
                this.createNewDashboard();
            }
            else {
                this.saveViewLoader = true
                axios.put(url, payload).then((res) => {
                    this.saveViewLoader = false
                    this.$toast.success("Successfully saved!", {
                        position: "top-center",
                        timeout: 2000,
                        closeOnClick: true,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                }).catch((err) => {
                    this.saveViewLoader = false
                    console.log(err)
                })
            }

        },

        createNewDashboard() {
            const url = "/api/v1/meta/savecreativeview"
            const payload = {
                "view_name": this.selectedViewName,
                "start_date": moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                "end_date": moment(this.dateRange.endDate).format("YYYY-MM-DD"),
            }
            this.renameDashboarddNameLoader = true
            axios.post(url, payload).then((res) => {
                this.isCommonModal.hide();
                this.renameDashboardName = '',
                    this.getSaveViewListData(false);
                this.renameDashboarddNameLoader = false
                this.$toast.success("Successfully Created New Dashboard", {
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            }).catch((err) => {
                this.renameDashboarddNameLoader = false
                this.$toast.warning("Dashboard with same name already exists", {
                    position: "top-center",
                    timeout: 5000,
                    closeOnClick: true,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                console.log(err)
            })
        },

        pinDashboardTab() {
            const url = "/api/v1/meta/savecreativeview"
            const payload = {
                view_name: this.selectedViewName,
                view_order: 1
            }
            axios.put(url, payload).then((res) => {
                this.getSaveViewListData(false);
                this.$toast.success("Dashboard Updated Successfully", {
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            }).catch((err) => {
                console.log(err)
            })
        },

        updateViewName() {
            if (this.isNewDashboard) {
                this.selectedViewName = this.renameDashboardName
                this.postSelectedFilter();
                return false
            }
            const url = "/api/v1/meta/savecreativeview"
            this.renameDashboarddNameLoader = true
            const payload = {
                view_name: this.selectedViewName,
                new_view_name: this.renameDashboardName
            }
            axios.put(url, payload).then((res) => {
                this.renameDashboarddNameLoader = false
                this.selectedViewName = this.renameDashboardName
                this.getSaveViewListData(false);
                this.isCommonModal.hide();
                this.$toast.success("Dashboard Renamed Successfully", {
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            }).catch((err) => {
                this.renameDashboarddNameLoader = false
                console.log(err)
            })
        },

        deleteSavedViewName() {
            this.dashboardDeleteLoader = true
            const url = "/api/v1/meta/savecreativeview"
            const payload = {
                view_name: this.selectedViewName
            }
            axios.delete(url, { data: payload }).then((res) => {
                this.getSaveViewListData();
                this.dashboardDeleteLoader = false
                this.$toast.success("Dashboard Deleted Successfully!", {
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                this.isDeleteDashboardModal.hide();
            }).catch((err) => {
                this.dashboardDeleteLoader = false
                console.log(err)
            })
        },

        async getMeteCreativeTimelineGraphData() {
            this.chartLoader = true;
            this.viewMeteCreativeTimelineGraphData = ''
            const url = "/api/v1/meta/creativetimleine/graph"
            const payload = {
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
                interval: this.selectedInterval.key
            }
            await axios.post(url, payload).then(res => {
                this.chartLoader = false;
                this.viewMeteCreativeTimelineGraphData = res.data.data
                this.graphDropDownData = Object.keys(res.data.data[0]).filter((value) => value != "Timestamp");
                // this.selectedLineGraphMetric = this.graphDropDownData[0]
                // this.selectedBarGraphMetric = this.graphDropDownData[1]
                this.processCompDropdown();
                this.processGraphData();
            }).catch(err => {
                this.chartLoader = false;

                console.log("Error in getting saved reports", err)
            })
        },

        async getMeteCreativeMonthWiseGraphData() {
            this.monthChartLoader = true;
            this.viewMonthWiseGraphData = ''
            this.monthWiseGraphData = ''
            const url = "/api/v1/meta/creativetimleine/graph"
            const payload = {
                start_date: moment(this.selectedMonthPicker).startOf('month').format("YYYY-MM-DD"),
                end_date: moment(this.selectedMonthPicker).endOf('month').format("YYYY-MM-DD"),
                interval: 'day'
            }
            await axios.post(url, payload).then(res => {
                this.monthChartLoader = false;
                this.viewMonthWiseGraphData = res.data.data
                this.processMonthWiseGraphData();
            }).catch(err => {
                this.monthChartLoader = false;
                console.log("Error in getting saved reports", err)
            })
        },

        processCompDropdown() {
            this.barGraphDropdownMetric = this.graphDropDownData.filter((value) => value != this.selectedLineGraphMetric);
            this.lineGraphDropdownMetric = this.graphDropDownData.filter((value) => value != this.selectedBarGraphMetric);
        },

        processGraphData() {
            this.processCompDropdown();
            this.timeLineGraphData = {
                xAxis: [],
                disableYLabel: true,
                seriesData: [{
                    name: this.selectedLineGraphMetric,
                    data: []
                },
                {
                    name: this.selectedBarGraphMetric,
                    data: []
                }
                ]
            }
            if (this.viewMeteCreativeTimelineGraphData) {
                this.viewMeteCreativeTimelineGraphData.forEach((data) => {
                    this.timeLineGraphData.xAxis.push(data.Timestamp);
                    this.timeLineGraphData.seriesData[0].data.push(data[this.selectedLineGraphMetric]);
                    this.timeLineGraphData.seriesData[1].data.push(data[this.selectedBarGraphMetric]);
                })
            }
        },

        processMonthWiseGraphData() {
            this.monthWiseGraphData = {
                xAxis: [],
                disableYLabel: true,
                legend: true,
                seriesData: [{
                    name: 'Revenue (GA4)',
                    type: 'bar',
                    data: []
                },
                {
                    name: 'Spends (Panel)',
                    type: 'bar',
                    data: []
                },
                {
                    name: 'ROI',
                    type: 'line',
                    yAxis: 2,
                    data: []
                }
                ]
            }
            if (this.viewMonthWiseGraphData) {
                this.viewMonthWiseGraphData.forEach((data) => {
                    this.monthWiseGraphData.xAxis.push(moment(data.Timestamp).format('DD'));
                    this.monthWiseGraphData.seriesData[0].data.push(data['GA Revenue']);
                    this.monthWiseGraphData.seriesData[1].data.push(data['Spend']);
                    this.monthWiseGraphData.seriesData[2].data.push(data['ROAS']);

                })
            }
        },

        processTable(id) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                scrollCollapse: true,
                lengthChange: true,
                ordering: false,
                // sorting: [[sortCol, "asc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },

        abbreviateNumber(value) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },

        formatNumber(num) {
            if (isNaN(num)) { return num }
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}